<template>
<div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 1.5rem;">
    <div class="action-selection__content">
        <h1>{{ $t('GenderSelection.title') }}</h1>
        <p>
            {{ $t('GenderSelection.message') }}
        </p>
        <PtySelector :options="possibleGenders" v-model="selectedGender" :value="selectedGender" colorizeMode="stroke" />

    </div>
</div>
</template>

<script>
import PtySelector from '../../components/PtySelector.vue';

import CheckIcon from '../../assets/images/icons/check.svg';

export default {
    name: 'GenderSelection',
    components: {
        PtySelector
    },
    data: () => ({
        selectedGender: null
    }),

    computed: {
        possibleGenders() {
            return [
                { id: 'MALE', title: this.$t('GenderSelection.options.male.title'), caption: this.$t('GenderSelection.options.male.caption'), icon: CheckIcon },
                { id: 'FEMALE', title: this.$t('GenderSelection.options.female.title'), caption: this.$t('GenderSelection.options.female.caption'), icon: CheckIcon },
                { id: 'COUPLE', title: this.$t('GenderSelection.options.couple.title'), caption: this.$t('GenderSelection.options.couple.caption'), icon: CheckIcon }
            ];
        }
    },

    props: {
        value: {
            type: String,
            default: null
        }
    },

    mounted() {
        if(this.value != undefined && this.value != null) {
            this.selectedGender = this.value;
        }
    },

    watch: {
        selectedGender() {
            this.$emit('input', this.selectedGender);
        }
    }
}
</script>


<style scoped>
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #ABABAB;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%; /* 1.88169rem */
}
</style>