<template>
<div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 1.5rem;">
    <div class="action-selection__content">
        <h1>{{ $t('TablePreference.associationTitle') }}</h1>
        <p>
        {{ $t('TablePreference.associationMessage') }}
        </p>
    </div>

    <div class="action-selection__code">
        <input type="tel" pattern="[0-9]*" novalidate style="width: 0; height: 0; opacity: 0; position: absolute;" v-model="code" ref="codeInput" >
        <PtyButton id="pty__TablePreference_insertCodeMessage" shadow :gray="digit == null" v-for="(digit, index) in digits" :key="index" style="width:65px; height: 65px;" @click="handleDigit(index)">
        <h1 style="color: white;">{{ digit != null ? digit : '' }}</h1>
        </PtyButton>
    </div>

    <p>
        {{ $t('TablePreference.insertCodeMessage') }}
    </p>
    <PtyButton id="pty__dvpl2buzk" gray shadow style="height: 65px;">
        <h1 style="color: rgba(var(--vs-primary)); margin:0;">{{ myCode }}</h1>
    </PtyButton>
</div>
</template>

<script>
import PtyButton from '../../components/PtyButton.vue';


export default {
    name: 'TablePreference',
    components: {
        PtyButton,
    },
    data: () => ({
        digits: [null, null, null, null],
        selIndex: -1,
        code: '',

    }),

    props: {
        myCode: {
            type: String,
            default: ''
        }
    },

 

    watch: {
        code(n, o){
            if(n.length > o.length){
                // added a digit
                var digits = this.code.split('');
            
                // take last digit
                var lastDigit = digits[digits.length - 1];
                if(lastDigit != null && this.selIndex != -1){
                    this.digits[this.selIndex] = lastDigit;
                    if(this.selIndex < 3){
                        this.selIndex++;
                    }
                }
            } else {
                // deleted
                this.digits[this.selIndex] = null;
                if(this.selIndex > 0){
                    this.selIndex--;
                }
            }

            // if is 4 digits, emit
            if(this.digits.filter(d => d != null).length == 4){
                this.$emit('input', this.digits.join(''));
            }
        }
    },

    methods: {
        handleDigit(index){
            this.digits[index] = null;

            this.digits.__ob__.dep.notify();
            this.$refs.codeInput.focus();
            this.selIndex = index;
        }
    }
}
</script>

<style scoped>

.action-selection__code {
    display: flex;
    gap: 1rem;
    position: relative;
}
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 20px;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

h2 {
    color: #FFF;
    font-family: Avenir;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 900;
}

span {
    color: rgba(var(--vs-primary),1)!important;
    font-weight: bold;
}

p {
    color: #ABABAB;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
}
</style>
