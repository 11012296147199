<template>
<div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 1.5rem; width:100%;">
    <div class="action-selection__content" style="width:100%;">
        <h1>{{ $t('Nickname.title') }}</h1>
        <p>
            {{ $t('Nickname.message') }}
        </p>
        
        <PtyInput id="pty__Nickname_nicknamePlaceholder" v-model="participant.nickname" :label="$t('Nickname.nickname')" :placeholder="$t('Nickname.nicknamePlaceholder')" block />
        <PtyInput id="pty__Nickname_agePlaceholder" v-model="participant.age" :label="$t('Nickname.age')" :placeholder="$t('Nickname.agePlaceholder')" block />
        <PtyInput id="pty__Nickname_cityPlaceholder" v-model="participant.city" :label="$t('Nickname.city')" :placeholder="$t('Nickname.cityPlaceholder')" block/>
    </div>
</div>
</template>

<script>
import PtyInput from '../../components/PtyInput.vue';

export default {
    name: 'Nickname',
    components: {
        PtyInput
    },
    data: () => ({
        participant: {
            nickname: '',
            age: '',
            city: '',
        }
    }),

    computed: {
    },

    props: {
        value: {
            type: Object,
            default: () => ({
                nickname: '',
                age: '',
                city: '',
            })
        }
    },

    mounted() { 
        if(this.value != undefined && this.value != null) {
            this.participant.nickname = this.value.nickname;
            this.participant.age = !isNaN(this.value.age) ? this.value.age : '';
            this.participant.city = this.value.city;
        }

    },

    watch: {
        participant: {
            deep: true,
            handler: function() {
                // capitalize first letter of nickname and city
                this.participant.nickname = this.participant.nickname.charAt(0).toUpperCase() + this.participant.nickname.slice(1);
                this.participant.city = this.participant.city.charAt(0).toUpperCase() + this.participant.city.slice(1);

                this.$emit('input', {
                    nickname: this.participant.nickname,
                    age: parseInt(this.participant.age),
                    city: this.participant.city,
                });
            }
        }
    }
}
</script>


<style scoped>
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #ABABAB;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%; /* 1.88169rem */
}
</style>